import { forwardRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import CloseIcon from '@/icons/close.svg';

type DialogProps = DialogPrimitive.DialogProps;

export default function Dialog(props: DialogProps) {
  return <DialogPrimitive.Root {...props} />;
}

Dialog.Title = forwardRef<HTMLHeadingElement, DialogPrimitive.DialogTitleProps>(
  ({ className, ...props }, ref) => (
    <DialogPrimitive.Title
      className={clsx('dialog__heading', className)}
      ref={ref}
      {...props}
    />
  )
);

Dialog.Trigger = forwardRef<
  HTMLButtonElement,
  DialogPrimitive.DialogTriggerProps
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Trigger
    className={clsx('dialog__trigger', className)}
    {...props}
    ref={ref}
  />
));

Dialog.Portal = DialogPrimitive.Portal;

Dialog.Overlay = forwardRef<HTMLDivElement, DialogPrimitive.DialogOverlayProps>(
  ({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
      className={clsx('dialog__overlay', className)}
      {...props}
      ref={ref}
    />
  )
);

Dialog.CloseButton = DialogPrimitive.Close;

Dialog.Close = forwardRef<
  HTMLButtonElement,
  Omit<DialogPrimitive.DialogCloseProps, 'children'>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close className={clsx('dialog__close')} {...props} ref={ref}>
    <CloseIcon className="dialog__close-icon" />
  </DialogPrimitive.Close>
));

Dialog.Content = forwardRef<HTMLDivElement, DialogPrimitive.DialogContentProps>(
  ({ className, children, ...props }, ref) => (
    <DialogPrimitive.Content
      className={clsx('dialog__content', className)}
      {...props}
      ref={ref}
    >
      <div className="dialog__inner">{children}</div>
    </DialogPrimitive.Content>
  )
);

import { forwardRef } from 'react';
import clsx from 'clsx';
import Arrow from '@/icons/right-arrow.svg';
import Download from '@/icons/download.svg';
import Filter from '@/icons/filter.svg';
import Loading from '@/icons/loading.svg';
import PlusIcon from '@/icons/plus-icon.svg';
import SearchIcon from '@/icons/search.svg';
import TickIcon from '@/icons/tick.svg';

interface ButtonProps extends React.ComponentProps<'button'> {
  icon?:
    | 'arrow'
    | 'download'
    | 'filter'
    | 'loading'
    | 'plus'
    | 'search'
    | 'tick';
  iconSide?: 'left' | 'right';
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'primary-outline'
    | 'secondary-outline'
    | 'success-outline'
    | 'tertiary-outline';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      icon,
      iconSide = 'right',
      isLoading,
      size = 'medium',
      type,
      variant = 'primary',
      ...props
    },
    ref
  ) => (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type ?? 'button'}
      ref={ref}
      className={clsx(
        'btn',
        `btn--${variant}`,
        className,
        size && `btn--${size}`
      )}
      {...props}
    >
      {isLoading ? (
        <Loading className="btn__icon btn__icon--loading" />
      ) : icon ? (
        <>
          {iconSide === 'right' && children && (
            <span className="btn__label">{children}</span>
          )}
          {icon === 'arrow' && <Arrow className="btn__icon btn__icon--arrow" />}
          {icon === 'download' && (
            <Download className="btn__icon btn__icon--download" />
          )}
          {icon === 'filter' && (
            <Filter className="btn__icon btn__icon--filter" />
          )}
          {icon === 'plus' && (
            <PlusIcon className="btn__icon btn__icon--plus" />
          )}
          {icon === 'search' && (
            <SearchIcon className="btn__icon btn__icon--search" />
          )}
          {icon === 'tick' && (
            <TickIcon className="btn__icon btn__icon--tick" />
          )}
          {iconSide === 'left' && children && (
            <span className="btn__label">{children}</span>
          )}
        </>
      ) : (
        children
      )}
    </button>
  )
);

export default Button;

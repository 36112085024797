import { type AppType } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { Session } from 'next-auth';
import { Toaster } from 'react-hot-toast';
import { trpc } from '@/lib/trpc';

import '@/styles/app.scss';
import PendoProvider from '@/components/PendoProvider';
import { inter } from '@/server/utils/fonts';

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps,
}) => (
  <>
    {/* eslint-disable-next-line react/no-unknown-property */}
    <style jsx global>{`
      html {
        font-family: ${inter.style.fontFamily};
      }
    `}</style>
    <SessionProvider session={pageProps.session}>
      <PendoProvider>
        <Toaster position="top-center" containerStyle={{ top: 102 }} />
        <Component {...pageProps} />
      </PendoProvider>
    </SessionProvider>
  </>
);

export default trpc.withTRPC(MyApp);

import Script from 'next/script';
import { trpc } from '@/lib/trpc';

interface PendoProps {
  children: React.ReactNode;
}

export default function PendoProvider({ children }: PendoProps) {
  const { data: user } = trpc.users.getCurrentUser.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!user) return <>{children}</>;

  return (
    <>
      <Script
        id="pendo"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(apiKey){
          (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
          v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
          pendo.initialize({
            visitor: {
              id: '${user.id ?? ''}',
              email: '${user.email}',
              full_name: '${user.name}',
            },
            account: {
              id: '${user?.customer?.externalOrgId ?? ''}',
              name: '${user?.customer?.name ?? ''}',
            }
          });
          })('96a24d67-58ab-4936-535e-200dfdebacfe');
          `,
        }}
      />
      {children}
    </>
  );
}

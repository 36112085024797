import { useState, forwardRef } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import ShowPasswordIcon from '@/icons/show-icon.svg';
import HidePasswordIcon from '@/icons/hide-icon.svg';

interface InputGroupProps extends React.ComponentProps<'input'> {
  errorMessage?: React.ReactNode;
  fieldSize?: 'small' | 'large';
  isError?: boolean;
  isRequired?: boolean;
  label?: React.ReactNode;
  leadingIcon?: React.ReactNode;
  link?: string;
  linkLabel?: string;
  wrapperClassName?: string;
}

const getType = (type: InputGroupProps['type'], showPassword: boolean) => {
  if (type === 'password' && showPassword) return 'text';
  return type;
};

const InputGroup = forwardRef<HTMLInputElement, InputGroupProps>(
  (props, ref) => {
    const {
      className,
      errorMessage,
      fieldSize,
      isError,
      isRequired = false,
      label,
      leadingIcon,
      link,
      linkLabel,
      type,
      wrapperClassName,
      ...rest
    } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div
        className={clsx(
          'input-group',
          wrapperClassName,
          fieldSize && `input-group--${fieldSize}`
        )}
      >
        {(label || link) && (
          <div className="input-group__label-wrap">
            {label && (
              <span
                className={clsx(
                  'label input-group__label',
                  fieldSize &&
                    `label--${fieldSize} input-group__label--${fieldSize}`
                )}
              >
                {label}
                {isRequired && <span className="input-group__asterisk">*</span>}
              </span>
            )}
            {link && (
              <Link
                className={clsx(
                  'link input-group__link',
                  fieldSize &&
                    `link--${fieldSize} input-group__link--${fieldSize}`
                )}
                href={link}
                passHref
              >
                {linkLabel ?? ''}
              </Link>
            )}
          </div>
        )}
        <div className="input-group__field-wrap">
          {leadingIcon && (
            <span className="input-group__icon">{leadingIcon}</span>
          )}
          <input
            ref={ref}
            type={getType(type, showPassword)}
            className={clsx(
              'input-group__field',
              (errorMessage || isError) && 'input-group__field--error',
              leadingIcon && 'input-group__field--leading',
              className,
              fieldSize && `input-group__field--${fieldSize}`
            )}
            title=""
            {...rest}
          />
          {type === 'password' && (
            <div className="input-group__button-wrap">
              <button
                className="input-group__button"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword && (
                  <HidePasswordIcon className="input-group__password input-group__password--hide" />
                )}
                {!showPassword && (
                  <ShowPasswordIcon className="input-group__password input-group__password--show" />
                )}
              </button>

              <p className="input-group__text-label">
                {showPassword ? 'Hide password' : 'Show password'}
              </p>
            </div>
          )}
        </div>

        {errorMessage && (
          <span
            className={clsx(
              'input-group__error',
              fieldSize && `input-group__error--${fieldSize}`
            )}
          >
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
);

export default InputGroup;

import { useState } from 'react';
import useDigitInput from 'react-digit-input';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import Link from 'next/link';

interface DigitsProps {
  className?: string;
  showMessage?: boolean;
}

export default function Digits({
  className,
  showMessage = false,
}: DigitsProps) {
  const [value, setValue] = useState('');
  const methods = useFormContext();

  // 2FA code input
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange: (val) => {
      setValue(val);
      methods.setValue('code', val);
    },
  });

  return (
    <>
      <fieldset className={clsx('digits', className)}>
        <legend className="digits__label">2FA Code</legend>
        <input
          className="digits__input"
          inputMode="decimal"
          {...digits[0]}
          placeholder="0"
        />
        <input
          className="digits__input"
          inputMode="decimal"
          {...digits[1]}
          placeholder="0"
        />
        <input
          className="digits__input"
          inputMode="decimal"
          {...digits[2]}
          placeholder="0"
        />
        <input
          className="digits__input"
          inputMode="decimal"
          {...digits[3]}
          placeholder="0"
        />
        <input
          className="digits__input"
          inputMode="decimal"
          {...digits[4]}
          placeholder="0"
        />
        <input
          className="digits__input"
          inputMode="decimal"
          {...digits[5]}
          placeholder="0"
        />

        <input className="digits__hidden" readOnly name="code" value={value} />
      </fieldset>
      {showMessage && (
        <p className="digits__message">
          Having trouble logging in?{' '}
          <Link className="link digits__link" href="/disable-2fa/">
            Disable sign-in 2FA.
          </Link>
        </p>
      )}
    </>
  );
}
